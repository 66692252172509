@font-face {
	font-family: 'Avenir Next Cyr';
	src: local(''), 
		url('../public/assets/fonts/Avenir/AvenirNextCyr-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: local(''),
		url('../public/assets/fonts/Avenir/AvenirNextCyr-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: local(''), 
		url('../public/assets/fonts/Avenir/AvenirNextCyr-Demi.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: local(''),
		url('../public/assets/fonts/Avenir/AvenirNextCyr-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: local(''),
		url('../public/assets/fonts/Avenir/AvenirNextCyr-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: local(''), 
		url('../public/assets/fonts/Avenir/AvenirNextCyr-HeavyItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: local(''), 
		url('../public/assets/fonts/Avenir/AvenirNextCyr-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: local(''), 
		url('../public/assets/fonts/Avenir/AvenirNextCyr-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: local(''), 
		url('../public/assets/fonts/Avenir/AvenirNextCyr-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Cyr Ultra';
	src: local(''),
		url('../public/assets/fonts/Avenir/AvenirNextCyr-UltraLightIt.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: local(''), 
		url('../public/assets/fonts/Avenir/AvenirNextCyr-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Cyr Ultra';
	src: local(''), 
		url('../public/assets/fonts/Avenir/AvenirNextCyr-UltraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: local(''),
		url('../public/assets/fonts/Avenir/AvenirNextCyr-Heavy.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: local(''), 
		url('../public/assets/fonts/Avenir/AvenirNextCyr-Bold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: local(''),
		url('../public/assets/fonts/Avenir/AvenirNextCyr-DemiItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: local(''), 
		url('../public/assets/fonts/Avenir/AvenirNextCyr-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

.pdf .react-pdf__Page__canvas {
	width: 100% !important;
	height: auto !important;
}

body {
  margin: 0;
  font-family: 'Avenir Next Cyr';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;

  font-family: 'Avenir Next Cyr', sans-serif;
  font-weight: 400;
}